/* eslint-disable default-case */
import { produce } from "immer";
import { AppActionsTypes } from "./app-actions-types";

const { SET_LOADING, SET_PLATFORM_INFORMATION } = AppActionsTypes;

const defaultState = {
  loading: true,
  platform: {},
};

const AppReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LOADING:
        draft.loading = action.payload.status;
        break;
      case SET_PLATFORM_INFORMATION:
        draft.platform = action.payload;
        break;
    }
  });

export default AppReducer;
