/* eslint-disable camelcase */

const languages = require("./assets/json/languages");

const langs = {};

const importAll = (r) => {
  console.log("Context keys:", r.keys()); // Check if keys are fetched correctly
  r?.keys()?.forEach((key, i) => {
    if (i > 1) {
      return;
    }
    console.log(i);
    console.log("Processing key:", key); // Log each key
    console.log(key.split("./")[1].split(".json")[0]);
    langs[key.split("./")[1].split(".json")[0]] = r(key);
  });
  console.log("Lanfs", langs);
};

importAll(require.context("./assets/json/languages/", false, /\.(json)$/));

const navigatorlangCode = (navigator.language || navigator.userLanguage).split("-")[0];

let currentLang = languages[0];

const lang_code = localStorage.getItem("lang_code");

if (lang_code) {
  currentLang = languages.find((language) => language.code === lang_code);
} else {
  languages.forEach((language) => {
    if (language.code === navigatorlangCode) {
      currentLang = language;
    }
  });
}

const setLang = (langCode) => {
  languages.forEach((language) => {
    if (language.code === langCode) {
      currentLang = language;
      localStorage.setItem("lang_code", langCode);
    }
  });
};

const getCurrentLang = () => currentLang;

const getLanguages = () => languages;

const getRawJsonFromLangCode = (code) => langs[code];

export default {
  setLang,
  getCurrentLang,
  getLanguages,
  getRawJsonFromLangCode,
};
