import { produce } from "immer";
import { ContextActionsTypes } from "./context-actions-types";

const { SET_CONTEXT, REMOVE_CONTEXT } = ContextActionsTypes;

const defaultState = {
  deal: null,
};

const ContextReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CONTEXT:
        return action.payload.value;
      case REMOVE_CONTEXT:
        return defaultState;
    }
  });

export default ContextReducer;
