import { produce } from "immer";

import { ThemeActionsTypes } from "./theme-actions-types";

const { CLEAR_THEME, SET_THEME } = ThemeActionsTypes;

const defaultState = {
  sidebar_expanded: true,
};

const ThemeReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_THEME:
        return action.payload;
      case CLEAR_THEME:
        return action.payload;
    }
  });

export default ThemeReducer;
