/* eslint-disable default-case */
import { produce } from "immer";

import { RemindersActionsTypes } from "./reminders-actions-types";
import { UserActionsTypes } from "../user/user-actions-types";

const { SET_REMINDERS, SET_TOTAL_REMINDERS } = RemindersActionsTypes;
const { RESET: LOGOUT } = UserActionsTypes;

const defaultState = {
  reminders: [],
  total: 0,
};

const RemindersReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_REMINDERS:
        draft.reminders = action.payload.reminders;
        break;
      case SET_TOTAL_REMINDERS:
        draft.total = action.payload.total;
        break;
      case LOGOUT:
        return defaultState;
    }
  });

export default RemindersReducer;
