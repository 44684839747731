import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Card } from "components/ui/atoms/Card";
import { useTranslation } from "react-i18next";
import Subsectors from "assets/json/subsectors.json";
import { useToasts } from "react-toast-notifications";
import Person from "assets/images/person.png";
import PitchIcon from "../../styles/images/pitch_icon.png";
import PitchDeckIcon from "../../styles/images/pitch_deck_icon.png";
import SalesDeckIcon from "../../styles/images/sales_deck_icon.png";
import BusinessPlanIcon from "../../styles/images/business_plan_icon.png";
import AnnualReportIcon from "../../styles/images/annual_report_icon.png";
import CaptableIcon from "../../styles/images/captable_icon.png";
import CvIcon from "../../styles/images/cv_icon.png";
import OthersDocumentsIcon from "../../styles/images/others_documents_icon.png";
import ReactTooltip from "react-tooltip";
import { SiCrunchbase } from "react-icons/si";
import Utils from "../../utils/utils";
import moment from "moment";
import { Loader } from "components/ui/molecules/Loader";
import {
  FaCalendarAlt,
  FaEnvelope,
  FaFileUpload,
  FaHandHoldingUsd,
  FaHandshake,
  FaInfoCircle,
  FaLightbulb,
  FaLink,
  FaMapMarkerAlt,
  FaPhone,
  FaQuestion,
  FaUser,
  FaGithub,
  FaGitlab,
  FaLinkedin,
  FaMedium,
  FaTwitter,
  FaYoutube,
  FaGlobe,
  FaProjectDiagram,
} from "react-icons/fa";
import { BsBarChartSteps } from "react-icons/bs";
import { BiTargetLock } from "react-icons/bi";
import { MdPriceChange } from "react-icons/md";
import { TagList } from "../tags/TagList";
import Linkedin from "../../assets/images/linkedin.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import Youtube from "../../assets/images/youtube.png";
import Crunchbase from "../../assets/images/Crunchbase.png";
import Facebook from "../../assets/images/facebook.png";
import { FiTarget } from "react-icons/fi";
import { DataGrid } from "../ui/objects/DataGrid";
import { PieChart } from "../ui/molecules/charts/PieChart";
import { NoDataInfo } from "../ui/atoms/NoDataInfo";

import useUser from "hooks/useUser";

import LineChart from "components/ui/molecules/charts/LineChart";
import { UploadFileSection } from "components/documentation/documents/components/UploadFileSection";
import TagsService from "services/tags-service";
import { Modal } from "components/ui/molecules/Modal";
import { ProgressBar } from "components/ui/atoms/ProgressBar";
import DeckIcon from "styles/images/deck_icon.png";
import OnePagerIcon from "styles/images/one_pager_icon.png";
import FinancialsIcon from "styles/images/financials_icon.png";
import DocumentsService from "services/documents-service";
import { ReferralsBar } from "components/dealflow/ReferralsBar";
import { BasicCardNotes } from "components/ui/molecules/BasicCardNotes";
import Tracking from "components/tracking";
import { TrafficLight } from "components/ui/atoms/TrafficLight";
import useCurrency from "hooks/useCurrency";
import DocumentPreview from "components/documentation/documents/components/DocumentPreview";
import { RoutesLinks } from "components/routes-links";
import { useNavigate, useOutletContext } from "react-router-dom";

export const StartupGeneralInformation = () => {
  const {
    deal,
    getDeal,
    deleteReminder,
    setReminderToEdit,
    markReminderAsCompleted,
    setSection,
    deleteNote,
    getNote,
    setShowMigrationSection,
    setShowNotesContainer,
    setLoading,
  } = useOutletContext();

  const infoToShow = deal || {};

  const { reminders, notes, investments } = deal;

  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { user, userLanguage, checkIfLPhasAccessToSection } = useUser();
  const [groupedInvesments, setGroupedInvestments] = useState({});
  const [showUploadDocumentSection, setShowUploadDocumentSection] = useState(false);
  const [tags, setTags] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [showPrevisualizationModal, setShowPrevisualizationModal] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const navigate = useNavigate();

  const { currency } = useCurrency();

  const getDocumentTags = () =>
    TagsService.getTags("documentation")
      .then((response) => {
        setTags(response.data.data);
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_retrieving_tags"),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });

  const previewDocument = async (documentId) => {
    setLoading(true);
    setPreviewUrl(null);
    try {
      let response = null;
      response = await DocumentsService.getDocumentPreview(documentId);

      if (response?.data?.data?.url) {
        setPreviewUrl(response?.data?.data?.url);

        setShowPrevisualizationModal(true);
      }
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t("error_occurred_downloading_document"),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const downloadDocument = async (documentId) => {
    setLoading(true);
    DocumentsService.getDocument(documentId)
      .then((response) => {
        if (response?.data?.data?.url) {
          window.open(response.data.data.url, "_blank");
        }
      })
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t("error_occurred_downloading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadDocument = async (document, tags, category) => {
    const formData = new FormData();
    formData.append("document", document);
    formData.append("document_name", document.name);

    formData.append("category", category);
    if (tags) {
      formData.append("tags", tags);
    }
    formData.append("startup", deal._id);

    return DocumentsService.uploadDocument(formData)
      .then(() => {})
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t("error_occurred_uploading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const uploadDocuments = async (
    documentType,
    documents,
    tags,
    selectedStartup,
    link,
    name,
    entityToAssignDocument,
    category
  ) => {
    if (documentType === "link") {
      setLoading(true);
      const data = { name, link, tags, category };
      if (entityToAssignDocument === "startup") {
        data.startup = selectedStartup;
      }

      DocumentsService.uploadLink(data)
        .then(() =>
          addToast(t("document_uploaded_successfully"), {
            appearance: "success",
            autoDismiss: true,
          })
        )
        .catch((error) =>
          addToast(
            error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t("error_occurred_uploading_document"),
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
        )
        .finally(() => setLoading(false));
    } else {
      if (Array.isArray(documents) && documents.length > 1) {
        setUploadingPercentage(1);

        for (const document of documents) {
          await uploadDocument(document, tags, category);
          setUploadingPercentage((prev) => Math.ceil(prev + 100 / documents.length + 1));
        }

        setUploadingPercentage(0);
        addToast(t("documents_uploaded_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        getDeal();
        setLoading(false);
      } else {
        setLoading(true);
        const file = Array.isArray(documents) ? documents[documents.length - 1] : documents;
        uploadDocument(file, tags, category)
          .then(() => {
            getDeal();
            addToast(t("document_uploaded_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const MAIN_DOCUMENTS = [
    {
      name: "dc_deck",
      icon: DeckIcon,
      document: infoToShow.deck,
    },
    {
      name: "dc_one_pager",
      icon: OnePagerIcon,
      document: infoToShow.one_pager,
    },
    {
      name: "dc_financials",
      icon: FinancialsIcon,
      document: infoToShow.financials,
    },
  ];

  const formatter = new Intl.DateTimeFormat(userLanguage, { month: "short", year: "numeric" });
  const headerClass = "text-main font-bold text-sm border-b border-main text-left bg-white";
  const documentIcons = {
    pitch: PitchIcon,
    pitch_deck: PitchDeckIcon,
    sales_deck: SalesDeckIcon,
    business_plan: BusinessPlanIcon,
    annual_report: AnnualReportIcon,
    captable: CaptableIcon,
    cv: CvIcon,
    others_documents: OthersDocumentsIcon,
  };
  const socialNetworks = [
    {
      name: "linkedin",
      icon: Linkedin,
      url: infoToShow.linkedin,
    },
    {
      name: "twitter",
      icon: Twitter,
      url: infoToShow.twitter,
    },
    {
      name: "instagram",
      icon: Instagram,
      url: infoToShow.instagram,
    },
    {
      name: "youtube",
      icon: Youtube,
      url: infoToShow.youtube,
    },
    {
      name: "crunchbase_profile",
      icon: Crunchbase,
      url: infoToShow.crunchbase_url,
    },
    {
      name: "facebook",
      icon: Facebook,
      url: infoToShow.facebook,
    },
  ].sort((a, b) => {
    if (a.url && b.url) {
      return a.url.length - b.url.length;
    } else if (a.url) {
      return -1;
    } else if (b.url) {
      return 1;
    }
    return 0;
  });
  const socialNetworksItemsFounder = [
    {
      name: "linkedin",
      title: "Linkedin",
      icon: <FaLinkedin />,
    },
    {
      name: "twitter",
      title: "Twitter",
      icon: <FaTwitter />,
    },
    {
      name: "youtube",
      title: "YouTube",
      icon: <FaYoutube />,
    },
    {
      name: "medium",
      title: "Medium",
      icon: <FaMedium />,
    },
    {
      name: "github",
      title: "GitHub",
      icon: <FaGithub />,
    },
    {
      name: "gitlab",
      title: "GitLab",
      icon: <FaGitlab />,
    },
    {
      name: "crunchbase_url",
      title: "Crunchbase",
      icon: <SiCrunchbase />,
    },
  ];

  const getFounderSocialNetworks = (user) => {
    const userNetworks = user?.social_networks || {};
    return Object.keys(userNetworks)
      .filter((n) => userNetworks[n])
      .map((n) => {
        const item = socialNetworksItemsFounder.find((i) => i.name === n);
        return item || null;
      });
  };

  const getSectors = () => [t(infoToShow.sector?.key), t(infoToShow.subsector?.key)].filter(Boolean).join(", ");

  const getOtherInterestingAreas = () =>
    Subsectors.filter((subsector) => (infoToShow.other_interesting_areas || []).includes(subsector._id))
      .map((subsector) => t(subsector.key))
      .join(", ");

  const parseMember = (member) => ({
    name: `${member.name} ${member.last_name}`,
    role: member.role ? t(member.role.key) : null,
    equity: member.percentage !== null && member.percentage !== undefined ? member.percentage : null,
    image: member.image ? member.image : Person,
    _id: member._id,
  });

  const renderBadge = (title, icon, value) => (
    <span
      data-tip={title}
      className={
        "rounded bg-gray-100 flex justify-between items-center gap-2 px-2 py-1 hover:bg-main hover:text-white parent-hover:text-white"
      }
    >
      <span className={"text-main"}>{icon}</span>
      <span className="font-semibold">{value}</span>
    </span>
  );

  useEffect(() => {
    getDocumentTags();
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [infoToShow]);

  useEffect(() => {
    investments.sort((a, b) => new Date(a.date) - new Date(b.date));

    const groupedData = investments.reduce((acc, curr) => {
      const date = new Date(curr.date);
      const key = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, "0")}01`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(curr);
      return acc;
    }, {});
    setGroupedInvestments(groupedData);
  }, [investments]);

  if (!deal) {
    return <Loader />;
  }

  const getAdditionalDocuments = () => {
    // Documentos principales
    const mainDocuments = ["deck", "one_pager", "financials"];

    // Contar cuántos documentos principales existen (no nulos o no indefinidos)
    const mainDocumentsCount = mainDocuments.reduce((count, doc) => {
      if (infoToShow[doc]) {
        return count + 1;
      }
      return count;
    }, 0);

    const additionalDocuments = infoToShow.total_documents - mainDocumentsCount;

    return additionalDocuments;
  };

  return (
    <>
      {showUploadDocumentSection && (
        <UploadFileSection
          onSubmit={uploadDocuments}
          onClose={() => setShowUploadDocumentSection(false)}
          tags={tags}
          dealId={deal._id}
        />
      )}
      <div className="mt-4">
        <div className="flex mt-4">
          <div className="flex-1 w-3/4 pr-4">
            <Card wrapperClassName="h-full" containerClassName="flex flex-col h-full">
              {deal.investor._id !== user.investor._id && (
                <div className="absolute top-3 right-3">
                  <div className="flex gap-2 items-center" role="button" data-tip={t("belongs_to_investor")}>
                    <FaProjectDiagram className={"text-main"} />
                    <span className="text-xxs">{deal.investor.company_name}</span>
                  </div>
                </div>
              )}
              <div className="flex">
                <div className="mr-4">
                  <Avatar
                    src={infoToShow.logo}
                    alt={infoToShow.name}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    size="100px"
                    round={false}
                    className={"avatar-startup"}
                  />
                </div>
                <div>
                  <h1 className={"text-4xl text-main mb-2 font-bold"}>
                    {infoToShow.name}
                    {infoToShow.web && (
                      <FaLink
                        className="inline h-4 ml-2 cursor-pointer"
                        onClick={() => window.open(infoToShow.web, "_blank")}
                      />
                    )}
                  </h1>
                  <div>
                    <p className="font-bold">{getSectors()}</p>
                    <p className="text-xs">{getOtherInterestingAreas()}</p>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-auto">{Utils.getDealflowStatus(deal, t)}</div>
                    {deal.priority && <div className="w-auto">{Utils.getDealflowPriority(deal, t)}</div>}
                    {deal.next_step && <div className="w-auto">{Utils.getDealflowNextStep(deal, t)}</div>}
                  </div>
                </div>
              </div>

              <div className="flex-1 p-2 mt-2 border-t border-gray-lines">
                {infoToShow.brand_statement ? (
                  <pre className="font-sans whitespace-pre-wrap">{infoToShow.brand_statement}</pre>
                ) : (
                  <span className="text-xs font-normal text-gray">{t("brand_statement_not_provided")}</span>
                )}
              </div>

              {(infoToShow.country_incorporation ||
                infoToShow.city_incorporation ||
                infoToShow.creation_date ||
                infoToShow.project_stage ||
                infoToShow.investment_stage ||
                infoToShow.business_model ||
                infoToShow.minimum_ticket ||
                infoToShow.investment_desired ||
                infoToShow.valuation) && (
                <div className="flex flex-row flex-wrap gap-2 mt-4 border-t border-gray-lines">
                  {(infoToShow.country_incorporation || infoToShow.city_incorporation) &&
                    renderBadge(
                      t("location"),
                      <FaMapMarkerAlt />,
                      [t(infoToShow.country_incorporation?.key), infoToShow.city_incorporation]
                        .filter(Boolean)
                        .join(", ")
                    )}

                  {infoToShow.creation_date &&
                    renderBadge(
                      t("creation_date"),
                      <FaCalendarAlt />,
                      moment(infoToShow.creation_date).format("DD/MM/YYYY")
                    )}

                  {infoToShow.project_stage &&
                    renderBadge(t("project_stage"), <BsBarChartSteps />, t(infoToShow.project_stage.key))}
                  {infoToShow.investment_stage &&
                    renderBadge(t("investment_stage"), <BsBarChartSteps />, t(infoToShow.investment_stage.key))}

                  {infoToShow.business_model &&
                    renderBadge(t("business_model"), <BiTargetLock />, t(infoToShow.business_model.key))}

                  {infoToShow.minimum_ticket &&
                    renderBadge(
                      t("minimum_ticket"),
                      <MdPriceChange />,
                      `${infoToShow.minimum_ticket} ${t(infoToShow.minimum_ticket_unit)}`
                    )}

                  {infoToShow.investment_desired &&
                    renderBadge(
                      t("investment_desired"),
                      <FaHandHoldingUsd />,
                      `${infoToShow.investment_desired} ${t(infoToShow.investment_desired_unit)}`
                    )}

                  {infoToShow.valuation &&
                    renderBadge(
                      t("valuation"),
                      <FaHandHoldingUsd />,
                      `${infoToShow.valuation} ${t(infoToShow.valuation_unit)}`
                    )}
                </div>
              )}

              {infoToShow && (
                <div className="flex-1 pt-2 my-2 border-t border-gray-lines">
                  <TagList showEmptyState={true} tags={infoToShow.tags || []} />
                </div>
              )}
              {infoToShow && (
                <div className="flex-1 py-2 border-t border-gray-lines">
                  {reminders.length === 0 && (
                    <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
                      {t("reminders_not_found")}
                    </span>
                  )}
                  {reminders.length !== 0 && (
                    <div>
                      <div>
                        <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 pt-0.5 pb-2">
                          {t("incoming_reminders")}
                        </span>
                      </div>
                      <div>
                        {reminders.map((reminder) =>
                          Utils.getReminderBadge(
                            reminder,
                            t,
                            deleteReminder,
                            setReminderToEdit,
                            markReminderAsCompleted
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {infoToShow && (
                <div className="flex-1 pt-2 my-2 border-t border-gray-lines">
                  {infoToShow?.referrals?.length > 0 && (
                    <div>
                      <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 pt-0.5 pb-2">
                        {t("referrals")}
                      </span>
                    </div>
                  )}
                  <ReferralsBar showEmptyState={true} referrals={infoToShow.referrals || []} />
                </div>
              )}
              {infoToShow && (
                <div className="flex items-center flex-1 pt-2 text-xs border-t border-gray-lines">
                  <FaUser className={"text-main mr-1"} data-tip={t("main_contact_person")} />
                  {infoToShow.main_contact_person || "-"}
                  <a
                    className="flex items-center"
                    href={infoToShow.main_contact_email !== undefined ? `mailto:${infoToShow.main_contact_email}` : ""}
                  >
                    <FaEnvelope className={"text-main ml-4 mr-1"} data-tip={t("main_contact_email")} />
                    {infoToShow.main_contact_email || "-"}
                  </a>
                  <FaPhone className={"text-main ml-4 mr-1"} data-tip={t("main_contact_phone")} />
                  {infoToShow.main_contact_phone || "-"}
                  <FaInfoCircle className={"text-main ml-4 mr-1"} data-tip={t("cif")} />
                  {infoToShow.cif || "-"}
                </div>
              )}

              {infoToShow && infoToShow.added_by && (
                <div className="flex-1 pt-2 my-2 border-t border-gray-lines">
                  <div>
                    <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 pt-0.5 pb-2">
                      {t("added_by")}
                    </span>
                  </div>
                  <span className="inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner">
                    {`${infoToShow.added_by.name} ${infoToShow.added_by.last_name}`}
                  </span>
                </div>
              )}
              {infoToShow && infoToShow.forms && infoToShow.forms[0] && (
                <div className="flex-1 pt-2 my-2 border-t border-gray-lines">
                  <div>
                    <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 pt-0.5 pb-2">
                      {t("added_by_form")}
                    </span>
                  </div>
                  {infoToShow.forms
                    .filter((form) => form.form)
                    .map((form) => (
                      <span
                        data-tip={`${t("application_date")}: ${new Date(form.date).toDateString()}`}
                        key={form._id}
                        onClick={() => navigate(`${RoutesLinks.QE_FORMS_LINK}/${form.form._id}`)}
                        role="button"
                        className="inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner"
                      >
                        {`${form.form.name}`}
                      </span>
                    ))}
                </div>
              )}

              {infoToShow && infoToShow.owners && infoToShow.owners.length ? (
                <div className="flex-1 pt-2 my-2 border-t border-gray-lines">
                  <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 pt-0.5 pb-2">{t("owners")}</span>
                  <div className="flex gap-2">
                    {infoToShow.owners.map((owner) => (
                      <span
                        key={owner._id}
                        className="inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner"
                      >{`${owner.name} ${owner.last_name}`}</span>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex-1 pt-2 my-2 border-t border-gray-lines">
                  <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
                    {t("no_owners")}
                  </span>
                </div>
              )}
            </Card>
          </div>

          <div className="w-1/4">
            <Card wrapperClassName={"mb-4"}>
              <div className="flex justify-between w-full">
                <div>
                  <h3 className={"text-main"}>
                    {getAdditionalDocuments()
                      ? `${t("main_documents")} (+${getAdditionalDocuments()})`
                      : t("main_documents")}
                  </h3>
                </div>
                {!user.lp && (
                  <div data-tip={t("upload_document")}>
                    <FaFileUpload
                      className="inline w-5 h-5 cursor-pointer"
                      onClick={() => setShowUploadDocumentSection(true)}
                    />
                  </div>
                )}
              </div>
              <div className="grid grid-cols-3 gap-4 py-4">
                {MAIN_DOCUMENTS.map(({ name, icon, document }) => {
                  const handleClick = () => {
                    if (document?.link) {
                      window.open(document.link, "_blank");
                    } else if (document) {
                      setSelectedDocument(document);
                      previewDocument(document._id);
                    }
                  };

                  return (
                    <div key={name} className="flex flex-row justify-center" data-tip={t(name)} onClick={handleClick}>
                      <img
                        src={icon}
                        alt={name}
                        className={
                          !document || document === "" ? "opacity-25 filter grayscale w-12" : "w-12 cursor-pointer"
                        }
                      />
                    </div>
                  );
                })}
              </div>
              {(!user.lp || checkIfLPhasAccessToSection("documentation")) && getAdditionalDocuments() !== 0 && (
                <div
                  className="flex justify-end mt-4 hover:font-bold hover:cursor-pointer"
                  onClick={() => setSection("documents")}
                >
                  {t("see_all_documents")}
                </div>
              )}
            </Card>
            <Card>
              <h3 className={"text-main"}>{t("social_networks")}</h3>
              <div className="grid grid-cols-3 gap-4 py-4">
                {socialNetworks.map(({ name, icon, url }) => (
                  <div key={name} className="flex flex-row justify-center">
                    <a
                      target="_blank"
                      href={url}
                      rel="noreferrer"
                      data-tip={!url || url === "" ? null : t(name)}
                      className={!url || url === "" ? "opacity-25 filter grayscale" : ""}
                    >
                      <img src={icon} alt={name} className={`${name === "crunchbase_profile" ? "w-10" : "w-8"}`} />
                    </a>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 my-8 md:grid-cols-2 xl:grid-cols-4">
          {["problem", "solution", "business_description", "market"].map((key) => (
            <div key={key} className="relative px-6 py-6 my-4 bg-white rounded-2xl">
              <div className="absolute flex items-center px-4 py-4 text-white rounded-full shadow-xl left-4 -top-6 bg-lightBlue-600">
                {key === "problem" && <FaQuestion className="inline-block w-6 h-6" />}
                {key === "solution" && <FaLightbulb className="inline-block w-6 h-6" />}
                {key === "business_description" && <FaHandshake className="inline-block w-6 h-6" />}
                {key === "market" && <FiTarget className="inline-block w-6 h-6" />}
              </div>

              <div className="flex flex-col justify-between h-full pt-6">
                <p className="mt-2 text-lg font-semibold">{t(key)}</p>
                <div className="flex flex-1 mt-2 text-base">
                  {infoToShow[key] && infoToShow[key] !== "" && infoToShow[key]}

                  {(!infoToShow[key] || infoToShow[key] === "") && <NoDataInfo title={t("no_market_section_data")} />}
                </div>
              </div>
            </div>
          ))}
        </div>

        {(!user?.preferences?.startup_profile || user?.preferences?.startup_profile?.annotations) &&
          (!user.lp || (user.lp && checkIfLPhasAccessToSection("annotations"))) && (
            <>
              <div className="flex justify-between my-8 border-b border-main">
                <div>
                  <h3 className={"text-2xl text-main"}>{`${t("notes")} ${
                    notes?.length > 0 && notes?.length < 4
                      ? ` (${notes.length}/${notes.length})`
                      : notes?.length > 0 && notes?.length >= 4
                      ? ` (4/${notes.length})`
                      : ""
                  }`}</h3>
                </div>
                {notes?.length > 4 && (
                  <div className="cursor-pointer hover:font-semibold" onClick={() => setShowNotesContainer(true)}>
                    {t("see_all_annotations")}
                  </div>
                )}
              </div>
              {notes?.length !== 0 && (
                <div className="grid gap-2 lg:grid-cols-2 xl:grid-cols-2">
                  {notes.slice(0, 4).map((note, index) => (
                    <div className="my-4" key={index}>
                      <BasicCardNotes
                        key={index}
                        note={note}
                        deleteNote={deleteNote}
                        getNote={getNote}
                        setShowMigrationSection={setShowMigrationSection}
                      />
                    </div>
                  ))}
                </div>
              )}

              {(!notes || notes?.length === 0) && (
                <div className="flex flex-1 mt-2 text-base">
                  <NoDataInfo title={t("no_notes_added")} />
                </div>
              )}
            </>
          )}
        {(!user?.preferences?.startup_profile || user?.preferences?.startup_profile?.custom_fields) && (
          <>
            <h3 className={"text-2xl text-main my-8 border-b border-main"}>{t("custom_fields")}</h3>
            {infoToShow.custom_fields && infoToShow.custom_fields.length !== 0 && (
              <Card containerClassName="flex flex-col h-full">
                <DataGrid
                  actionsHeaderClassName={headerClass}
                  flexColumn={false}
                  data={infoToShow.custom_fields}
                  headers={[
                    {
                      key: "custom_field",
                      title: null,
                      headerClassName: headerClass,
                      render: (key, customField) => (
                        <div className="font-semibold">{customField.custom_field.name}</div>
                      ),
                    },
                    {
                      key: "value",
                      headerClassName: headerClass,
                      title: null,
                      render: (key, customField) => {
                        if (["short_answer", "long_answer"].includes(customField.custom_field.type)) {
                          return customField.value;
                        } else if (customField.custom_field.type === "traffic_light") {
                          return (
                            <TrafficLight
                              identifier={customField.custom_field._id}
                              value={customField.value !== null ? parseInt(customField.value) : 0}
                              disabled={true}
                            />
                          );
                        } else {
                          return customField.options
                            ?.map((option) => customField.custom_field.options.find((o) => o._id === option)?.value)
                            .join(", ");
                        }
                      },
                    },
                  ]}
                />
              </Card>
            )}

            {(!infoToShow.custom_fields || infoToShow.custom_fields.length === 0) && (
              <div className="flex flex-1 mt-2 text-base">
                <NoDataInfo title={t("no_data_registered")} />
              </div>
            )}
          </>
        )}

        {infoToShow.presentation_video && (
          <>
            <h3 className={"text-2xl text-main mt-4 mb-4 border-b border-main"}>{t("presentation_video")}</h3>
            <div className="flex flex-row justify-center py-4">
              <iframe
                width="420"
                height="315"
                className="rounded-lg"
                allowFullScreen
                src={infoToShow.presentation_video.replace("watch?v=", "embed/")}
              ></iframe>
            </div>
          </>
        )}

        {(!user?.preferences?.startup_profile || user?.preferences?.startup_profile?.team) && (
          <>
            <h3 className={"text-2xl text-main mt-4 mb-4 border-b border-main"}>{t("team")}</h3>

            {infoToShow?.founders && infoToShow.founders.length !== 0 ? (
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
                {infoToShow?.founders
                  ?.map(parseMember)
                  .sort((a, b) => b.equity - a.equity)
                  .map((member) => (
                    <Card key={member._id} wrapperClassName="mt-[50px]" containerClassName="relative pt-10">
                      <div className="absolute w-full left-0 -top-[40px] flex justify-center items-center">
                        <Avatar
                          src={member.image}
                          alt={member.name}
                          color="#e0e6f2"
                          fgColor="#4d70b3"
                          size="80px"
                          round={true}
                          className={"avatar-startup"}
                        />
                      </div>

                      <h2 className="mt-2 text-sm text-center">{member.name}</h2>
                      <div className="text-xs text-center">
                        {member.role} {member.equity && <span className="text-xxs">({member.equity}%)</span>}
                      </div>
                      <h5 className="mt-2 text-sm text-center">{member.description}</h5>
                      {getFounderSocialNetworks(member).length > 0 && (
                        <div className="pt-6 mt-6 text-center border-t border-slate-200">
                          <div className="flex items-center justify-center py-2">
                            {getFounderSocialNetworks(member).map((item) => (
                              <a
                                key={item.name}
                                href={member.social_networks[item.name]}
                                target="_blank"
                                rel="noreferrer"
                                data-tip={item.title}
                                className={
                                  "w-8 h-8 flex justify-center items-center rounded-full bg-main text-white mx-2"
                                }
                              >
                                {item.icon}
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                    </Card>
                  ))}
              </div>
            ) : (
              <div className="my-6">
                <NoDataInfo title={t("no_data_registered")} />
              </div>
            )}
          </>
        )}

        {(!user?.preferences?.startup_profile || user?.preferences?.startup_profile?.investments) && (
          <>
            <h3 className={"text-2xl text-main my-8 border-b border-main"}>{t("investments")}</h3>
            {investments.length === 0 && (
              <div className="my-6">
                <NoDataInfo title={t("no_data_registered")} />
              </div>
            )}
            {investments.length !== 0 && (
              <Card>
                <div className="w-full mt-6 h-96">
                  <LineChart
                    marginLeft={50}
                    marginRight={50}
                    yFormat=">-.0f"
                    data={[
                      {
                        id: "dealflow",
                        data: Object.keys(groupedInvesments)
                          .map((key) => {
                            // Check if the key is in the format "YYYYMM"
                            if (!/^\d{6}$/.test(key)) {
                              console.warn(`Invalid key format: ${key}`);
                              return null; // Skip this key or handle it as needed
                            }

                            const year = parseInt(key.substring(0, 4), 10);
                            const month = parseInt(key.substring(4, 6), 10) - 1;

                            if (isNaN(year) || isNaN(month) || month < 0 || month > 11) {
                              console.warn(`Invalid date value from key: ${key}`);
                              return null;
                            }

                            const date = new Date(year, month, 1);

                            return {
                              x: formatter.format(date),
                              y: groupedInvesments[key].reduce((acc, curr) => acc + curr.full_investment, 0),
                            };
                          })
                          .filter((item) => item !== null),
                      },
                    ]}
                  />
                </div>
                <DataGrid
                  actionsHeaderClassName={headerClass}
                  data={investments}
                  headers={[
                    {
                      key: "shareholder",
                      title: t("investor"),
                      headerClassName: headerClass,
                      render: (key, investment) =>
                        investment.is_own_investment ? t("own_investment") : investment.shareholder,
                    },
                    {
                      key: "investment",
                      title: t("investment"),
                      headerClassName: headerClass,
                      render: (key, investment) =>
                        `${investment[key]}${investment?.unit?.toUpperCase() || "K"} ${currency}`,
                    },
                    {
                      key: "shares",
                      headerClassName: headerClass,
                      title: t("shares"),
                    },
                    {
                      key: "date",
                      title: t("date"),
                      headerClassName: headerClass,
                      render: (key, investment) =>
                        investment[key] ? moment(investment[key]).format("YYYY-MM-DD") : t("no_data"),
                    },
                  ]}
                />
              </Card>
            )}
          </>
        )}
        {(!user?.preferences?.startup_profile || user?.preferences?.startup_profile?.captable) && (
          <>
            <h3 className={"text-2xl text-main my-8 border-b border-main"}>{t("captable")}</h3>

            {infoToShow.captable && infoToShow.captable.length !== 0 && (
              <Card>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 pr-4 border-r border-gray-lines">
                    <DataGrid
                      actionsHeaderClassName={headerClass}
                      data={(infoToShow.captable || []).map((h, i) => ({ ...h, id: i }))}
                      headers={[
                        {
                          key: "stakeholder",
                          title: t("stakeholder"),
                          headerClassName: headerClass,
                        },
                        {
                          key: "investment",
                          title: t("investment"),
                          headerClassName: headerClass,
                          render: (key, shareholder) =>
                            `${shareholder[key]}${shareholder?.unit?.toUpperCase() || "K"} ${currency}`,
                        },
                        {
                          key: "shares",
                          title: t("shares"),
                          headerClassName: headerClass,
                        },
                        {
                          key: "incorporation_date",
                          title: t("incorporation_date"),
                          headerClassName: headerClass,
                          render: (key, stakeholder) =>
                            stakeholder.incorporation_date
                              ? moment(stakeholder.incorporation_date).format("DD/MM/YYYY")
                              : t("no_data"),
                        },
                      ]}
                    />
                  </div>

                  <div className="h-96">
                    <div className={"px-6 py-4 text-main font-bold text-sm"}>{t("equity_distribution")}</div>
                    <PieChart
                      legend={false}
                      data={(infoToShow?.captable || []).map((h) => ({
                        id: h.stakeholder,
                        value: h.shares,
                      }))}
                    />
                  </div>
                </div>
              </Card>
            )}
            {(!infoToShow.captable || infoToShow.captable.length === 0) && (
              <div className="flex flex-1 mt-2 text-base">
                <NoDataInfo title={t("no_data_registered")} />
              </div>
            )}
          </>
        )}

        {(!user?.preferences?.startup_profile || user?.preferences?.startup_profile?.competitors) && (
          <>
            <h3 className={"text-2xl text-main my-8 border-b border-main"}>{t("competitors")}</h3>

            {infoToShow.competitors && infoToShow.competitors.length !== 0 && (
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                {infoToShow.competitors.map((competitor, i) => (
                  <Card key={i} containerClassName="flex flex-col h-full">
                    <h2 className="mb-2 font-bold">{competitor.name}</h2>

                    {competitor.description && <p className="flex-1">{competitor.description}</p>}

                    {competitor.web && (
                      <p className={"border-t border-gray-lines pt-2 mt-2 text-main"}>
                        <FaGlobe className={"inline-block mr-2 text-main"} />
                        <a
                          href={competitor.web}
                          target="_blank"
                          rel="noreferrer"
                          className={"text-main hover:text-link-hover"}
                        >
                          {competitor.web}
                        </a>
                      </p>
                    )}
                  </Card>
                ))}
              </div>
            )}
            {(!infoToShow.competitors || infoToShow.competitors.length === 0) && (
              <div className="flex flex-1 mt-2 text-base">
                <NoDataInfo title={t("no_data_registered")} />
              </div>
            )}
          </>
        )}
        {(!user?.preferences?.startup_profile || user?.preferences?.startup_profile?.tracking) && (
          <Tracking deal={deal} />
        )}
      </div>

      {/* {loading && <Loader />} */}

      {uploadingPercentage > 0 && uploadingPercentage < 100 && (
        <Modal showModal={true}>
          <div className="w-72">
            <ProgressBar percentage={uploadingPercentage} value={uploadingPercentage} valueText={t("uploading")} />
          </div>
        </Modal>
      )}

      {showPrevisualizationModal && (
        <DocumentPreview
          previewUrl={previewUrl}
          document={selectedDocument}
          downloadDocument={() => downloadDocument(selectedDocument._id)}
          handleClose={() => setShowPrevisualizationModal(false)}
          refetch={() => previewDocument(selectedDocument._id)}
        />
      )}
    </>
  );
};
