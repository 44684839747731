/* eslint-disable default-case */
import { produce } from "immer";

import { UserActionsTypes } from "./user-actions-types";

const { SET_TOKEN, SET_API_KEY, RESET, SET_USER_INFO, SET_LOGIN_ENTRIES } = UserActionsTypes;

const defaultState = {
  auth: {
    token: "",
    api_key: "",
  },
  user: {},
  login_entries: {},
};

const authReducer = (state = defaultState.auth, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_TOKEN:
        return Object.assign({}, state, {
          token: action.payload.token,
        });
      case SET_API_KEY:
        return Object.assign({}, state, {
          api_key: action.payload.apiKey,
        });
      case RESET:
        return {};
    }
  });

const userReducer = (state = defaultState.user, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_USER_INFO:
        return action.payload.user_info;
      case RESET:
        return {};
    }
  });

const loginEntriesReducer = (state = defaultState.login_entries, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LOGIN_ENTRIES:
        return action.payload;
      case RESET:
        return [];
    }
  });

const UserReducer = {
  auth: authReducer,
  user: userReducer,
  loginEntries: loginEntriesReducer,
};

export default UserReducer;
